import * as React from "react"
import { BsClock, BsFillTelephoneFill, BsPhone } from "react-icons/bs";

const TopBar = () => {
  const [scrollY, setScrollY] = React.useState(0);

  function logit() {
    setScrollY(window.pageYOffset);
  }

  React.useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  return (
    <section id="topbar" className={`d-flex align-items-center fixed-top topbar-transparent ${scrollY > 100? "topbar-scrolled":""}`}>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-center justify-content-lg-start">
        <a href="tel:+48721399415">
          <i className="d-flex align-items-center"><BsFillTelephoneFill/><span>721 399 415</span></i>
        </a>
        <i className="ms-4 d-none d-lg-flex align-items-center"><BsClock/><span>Pon-Pt: 07:00 - 15:00</span></i>
      </div>
    </section>
  )
}
export default TopBar
