import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"
import { BsList, BsX } from "react-icons/bs";

const Header = ({ siteTitle, page }) => {
  const [scrollY, setScrollY] = React.useState(0);
  const [isActiveMobile, setActiveMobile] = React.useState(false);

  const handleToggle = () => {
    setActiveMobile(!isActiveMobile);
  };

  function changeValueY() {
    setScrollY(window.pageYOffset);
  }

  React.useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", changeValueY);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", changeValueY);
    };
  });


  return (
    <header id="header" className={`fixed-top d-flex align-items-center header-transparent ${scrollY > 100 ? "header-scrolled" : ""}`}>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

        <div className="logo me-auto">
          <h1><Link to="/">Barwy zdrowia</Link></h1>
        </div>

        {/* <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          <li><Link to="/" className="nav-link scrollto active" >Strona główna</Link></li>
          <li><Link to="/galeria" className="nav-link scrollto">Galeria</Link></li>
          <li><Link to="/cennik" className="nav-link scrollto">Cennik</Link></li>
          <li><Link to="/promocje" className="nav-link scrollto">Promocje</Link></li>
          <li><Link to="/dostawa" className="nav-link scrollto">Dostawa</Link></li>
          <li><Link to="/zamowienia" className="nav-link scrollto">Zamówienia</Link></li>
          <li><Link to="/kontakt" className="nav-link scrollto">Kontakt</Link></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav> */}

        <Nav as="nav" id="navbar" className={`navbar order-last order-lg-0 ${isActiveMobile ? "navbar-mobile" : ""}`} activeKey={page}>
          <ul>
            <Nav.Item as="li">
              <Link to="/" className="nav-link scrollto">
                <Nav.Link as="span" eventKey="index" className="nav-link scrollto">
                  Strona główna
                </Nav.Link>
              </Link>
            </Nav.Item>

            <Nav.Item as="li">
              <Link to="/galeria" className="nav-link scrollto">
                <Nav.Link as="span" eventKey="galeria" >
                  Galeria
                </Nav.Link>
              </Link>
            </Nav.Item>

            <Nav.Item as="li">
              <Link to="/cennik" className="nav-link scrollto">
                <Nav.Link as="span" eventKey="cennik" >
                  Cennik
                </Nav.Link>
              </Link>
            </Nav.Item>

            <Nav.Item as="li">
              <Link to="/dostawa" className="nav-link scrollto">
                <Nav.Link as="span" eventKey="dostawa" >
                  Dostawa
                </Nav.Link>
              </Link>
            </Nav.Item>

            <Nav.Item as="li">
              <Link to="/nasz-dietetyk" className="nav-link scrollto">
                <Nav.Link as="span" eventKey="nasz-dietetyk" >
                  Nasz dietetyk
                </Nav.Link>
              </Link>
            </Nav.Item>

            <Nav.Item as="li">
              <Link to="/zamowienia" className="nav-link scrollto">
                <Nav.Link as="span" eventKey="zamowienia" >
                  Zamówienia
                </Nav.Link>
              </Link>
            </Nav.Item>

            <Nav.Item as="li">
              <Link to="/kontakt" className="nav-link scrollto">
                <Nav.Link as="span" eventKey="kontakt" >
                  Kontakt
                </Nav.Link>
              </Link>
            </Nav.Item>
          </ul>
          <i className="mobile-nav-toggle"
            onClick={handleToggle}
          >
            {
              isActiveMobile ?
                <BsX /> :
                <BsList />
            }
          </i>
        </Nav>

      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
