import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const query = graphql`
{
  file(name: {eq: "decorative-elementor-top"}) {
    childImageSharp {
      gatsbyImageData(
        formats: WEBP  
        placeholder: BLURRED)
    }
  }
}
`

const DecorativeSectionTop = () => {
  const data = useStaticQuery(query);
  return (
      <SectionDecorativeTop>
        <DecorativeElementorTop css={{ backgroundImage: `url(${data.file.childImageSharp.gatsbyImageData.images.fallback.src})` }}/>
      </SectionDecorativeTop>
  )
}

const DecorativeElementorTop = styled.div`
  background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    height: 70px;
  z-index: 100;
  position: relative;
  `

const SectionDecorativeTop = styled.section`
  margin-bottom: -115px;
`


export default DecorativeSectionTop;