/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import TopBar from "./topBar"

import DecorativeSectionTop from "./DecorativeSectionTop"
import { BsArrowUpShort, BsFacebook, BsInstagram } from "react-icons/bs"
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet"

const Layout = ({ children, page }) => {
  const [scrollYForArrow, setscrollYForArrow] = React.useState(0);

  function changeValueYForArrow() {
    setscrollYForArrow(window.pageYOffset);
  }
  let AOS;

  React.useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: false,
    });
  }, []);

  React.useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });


  React.useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", changeValueYForArrow);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", changeValueYForArrow);
    };
  });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <TopBar />
      <Header
        siteTitle={data.site.siteMetadata?.title || `Barwy Zdrowia`}
        page={page}
      />

      <main id="main">
        {children}
      </main>
      
      <DecorativeSectionTop />
      <CookieConsent
        location="top"
        buttonText="Rozumiem"
        cookieName="CookieAlertBarwyZdrowia"
        // style={{ background: "#2B373B" }}
        buttonStyle={{ fontSize: "13px" }}
        expires={150}
      >
        <span style={{ fontSize: "13px"}}> Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy.</span>
      </CookieConsent>
      <footer id="footer">
        <div className="container">
          <h3>Barwy zdrowia</h3>
          <p>Jesteś tym co jesz, to nie hasło, lecz udowodnione twierdzenie!</p>
          <div className="social-links">
            <a href="https://www.facebook.com/barwyzdrowia" target="_blank" className="facebook"><BsFacebook /></a>
            <a href="https://instagram.com/barwyzdrowiacatering" target="_blank" className="instagram"><BsInstagram /></a>
          </div>
          <div className="policy" ><a href="/regulamin"><span>Regulamin</span></a></div>
          <div className="copyright">
            &copy; Copyright <strong><span>2022</span></strong>. Wszelkie prawa zastrzeżone
          </div>
        </div>
      </footer>

      <a href="#" className={`back-to-top d-flex align-items-center justify-content-center ${scrollYForArrow > 100 ? "active" : ""}`}>
        <i><BsArrowUpShort /></i>
      </a>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
